<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <h5 class="mb-0">
          Metadata Utama
        </h5>
      </b-col>
      <b-col
        v-if="saktiLoad"
        cols="12"
      >
        <b-alert
          variant="warning"
          show
        >
          <div class="alert-body">
            <span>Memuat data <b>Sakti</b>, mohon menunggu</span>
          </div>
        </b-alert>
      </b-col>
      <b-col
        v-if="saktiFailed"
        cols="12"
      >
        <b-alert
          variant="danger"
          show
        >
          <h4 class="alert-heading">
            Error Found with Sakti
          </h4>
          <div class="alert-body">
            <span v-if="saktiFailedMsg != ''">
              {{ saktiFailedMsg }}
              <b-link
                v-if="saktiFailedLink"
                target="_blank"
                href="https://docs.google.com/forms/d/e/1FAIpQLSfRi98gWYJnGLThDh9AeCVRMhQQq5u_l4aCgKfWvlQ8pFf2ow/viewform"
              >link berikut</b-link>.
            </span>
            <span v-else>Konektivitas dengan <u>Sakti</u> gagal, Muat ulang Halaman untuk mencoba kembali atau hubungi <u>Admin</u></span>
          </div>
        </b-alert>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-if="useSakti"
          v-b-tooltip.hover.top="'Nama Unit Kerja sesuai dengan Renja'"
          label="Unit Kerja"
          label-for="vi-unit_kerja"
        >
          <validation-provider
            #default="{ errors }"
            name="Unit Kerja"
            rules="required"
          >
            <v-select
              id="vi-unit_kerja"
              v-model="unit_kerja"
              required
              name="unit_kerja"
              :options="saktiUnit"
              placeholder="Pilih Salah Satu"
              label="label"
              @input="saktiGetKegiatan()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          v-else
          v-b-tooltip.hover.top="'Nama Unit Kerja sesuai dengan Renja'"
          label="Nama Unit Kerja"
          label-for="vi-unit_kerja"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Unit Kerja"
            rules="required"
          >
            <b-input-group>
              <b-form-input
                id="vi-nama_unit_kerja"
                v-model="nama_unit_kerja"
                required
                name="nama_unit_kerja"
                placeholder="Unit Kerja (Lengkap Kode dan Nama)"
                :autofocus="(fokusRo === 'unit') ? true : false"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-secondary"
                  @click="unitReset"
                >
                  <feather-icon
                    icon="XCircleIcon"
                    size="12"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-if="useSakti"
          v-b-tooltip.hover.top="'Nama Kegiatan sesuai dengan Renja'"
          label="Nama Kegiatan"
          label-for="vi-nama_kegiatan"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Kegiatan"
            rules="required"
          >
            <v-select
              id="vi-nama_kegiatan"
              v-model="nama_kegiatan"
              required
              name="nama_kegiatan"
              :options="saktiKegiatan"
              placeholder="Pilih Salah Satu"
              label="label"
              @input="saktiGetKro()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          v-else
          v-b-tooltip.hover.top="'Nama Kegiatan sesuai dengan Renja'"
          label="Nama Kegiatan"
          label-for="vi-nama_kegiatan"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Kegiatan"
            rules="required"
          >
            <b-input-group>
              <b-form-input
                id="vi-nama_kegiatan"
                v-model="nama_kegiatan_sendiri"
                required
                name="nama_kegiatan"
                placeholder="Nama Kegiatan (Lengkap Kode dan Nama)"
                :autofocus="(fokusRo === 'giat') ? true : false"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-secondary"
                  @click="namaReset"
                >
                  <feather-icon
                    icon="XCircleIcon"
                    size="12"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Nama Penanggung Jawab sesuai dengan Renja'"
          label="Penanggung Jawab"
          label-for="vi-pic_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Penanggung Jawab"
            rules="required"
          >
            <v-select
              id="vi-pic_id"
              v-model="penanggungJawabModel"
              required
              name="pic_id"
              :options="referenceData.ref_pic"
              label="label"
              placeholder="Pilih Salah Satu atau Tambah Baru"
              @input="setContact()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <div v-if="penanggungJawabModel.pic_id === 'new'">
      <b-row>
        <b-col
          md="6"
          class="mb-1"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Nama Penanggung Jawab sesuai dengan Renja'"
            label="Nama Penanggung Jawab"
            label-for="vi-pic_name"
          >
            <validation-provider
              #default="{ errors }"
              name="Nama Penanggung Jawab"
              rules="required"
            >
              <b-form-input
                id="vi-pic_name"
                v-model="pic_name"
                required
                name="pic_name"
                placeholder="Nama Penanggung Jawab"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="mb-1"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Nomor Kontak Penanggung Jawab sesuai dengan Renja'"
            label="Nomor Penanggung Jawab"
            label-for="vi-pic_number"
          >
            <validation-provider
              #default="{ errors }"
              name="Nomor Penanggung Jawab"
              rules="required"
            >
              <b-form-input
                id="vi-pic_number"
                v-model="pic_number"
                type="number"
                required
                name="pic_number"
                placeholder="Nomor Penanggung Jawab"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <b-form-input
        v-model="pic_name"
        :value="penanggungJawabModel.name"
        hidden
        name="pic_name"
      />
      <b-form-input
        v-model="pic_number"
        :value="penanggungJawabModel.number"
        hidden
        name="pic_number"
      />
    </div>
    <b-row>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-if="useSakti"
          v-b-tooltip.hover.top="'Klasifikasi Rincian Output Program sesuai dengan Renja'"
          label="Klasifikasi Rincian Output (KRO)"
          label-for="vi-kode_model_referensi"
        >
          <validation-provider
            #default="{ errors }"
            name="Klasifikasi Rincian Output (KRO)"
            rules="required"
          >
            <v-select
              id="vi-kode_model_referensi"
              v-model="kode_model_referensi"
              required
              name="kode_model_referensi"
              :options="saktiKro"
              placeholder="Pilih Salah Satu"
              label="label"
              @input="saktiGetRo()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          v-else
          v-b-tooltip.hover.top="'Klasifikasi Rincian Output Program sesuai dengan Renja'"
          label="Klasifikasi Rincian Output (KRO)"
          label-for="vi-kode_model_referensi"
        >
          <validation-provider
            #default="{ errors }"
            name="Klasifikasi Rincian Output (KRO)"
            rules="required"
          >
            <b-input-group>
              <b-form-input
                id="vi-kode_model_referensi"
                v-model="nama_kode_model_referensi"
                required
                name="kode_model_referensi"
                placeholder="Klasifikasi Rincian Output (KRO) Lengkap Kode dan Nama"
                :autofocus="(fokusRo === 'kro') ? true : false"
              />
              <b-input-group-append>
                <b-button
                  v-if="nama_kode_model_referensi"
                  variant="outline-secondary"
                  @click="kroReset"
                >
                  <feather-icon
                    icon="XCircleIcon"
                    size="12"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-info">Pastikan penulisan KRO telah sesuai dan tepat.<br></small>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-if="useSakti"
          v-b-tooltip.hover.top="'Rincian Output Program sesuai dengan Renja'"
          label="Rincian Output (RO)"
          label-for="vi-rincian_output"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Kegiatan"
            rules="required"
          >
            <v-select
              id="vi-rincian_output"
              v-model="rincian_output"
              required
              name="rincian_output"
              :options="saktiRo"
              placeholder="Pilih Salah Satu"
              label="label"
              @input="saktiGetItem()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          v-else
          v-b-tooltip.hover.top="'Rincian Output Program sesuai dengan Renja'"
          label="Rincian Output (RO)"
          label-for="vi-rincian_output"
        >
          <validation-provider
            #default="{ errors }"
            name="Rincian Output (RO)"
            rules="required"
          >
            <b-form-input
              id="vi-rincian_output"
              v-model="rincian_output"
              required
              name="rincian_output"
              placeholder="Rincian Output (RO) Lengkap Kode dan Nama"
              :autofocus="(fokusRo === 'ro') ? true : false"
            />
            <small class="text-info">Pastikan penulisan RO telah sesuai dan tepat.<br></small>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Tahun Anggaran Program sesuai dengan Renja'"
          label="Tahun Anggaran"
          label-for="vi-tahun_anggaran"
        >
          <validation-provider
            #default="{ errors }"
            name="Tahun Anggaran"
            rules="required"
          >
            <b-form-input
              id="vi-tahun_anggaran"
              v-model="tahun_anggaran"
              type="number"
              required
              name="tahun_anggaran"
              placeholder="Tahun Anggaran"
              :disabled="(saktiFailed === true) ? false : true"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-if="useSakti"
          v-b-tooltip.hover.top="'Total Anggaran Program sesuai dengan Renja'"
          :label="`Total Anggaran ${(total_anggaran !== '') ? ': Rp. ' + Number(total_anggaran).toLocaleString() : ''}`"
          label-for="vi-total_anggaran"
        >
          <validation-provider
            #default="{ errors }"
            name="Total Anggaran"
            rules="required"
          >
            <b-form-input
              id="vi-total_anggaran"
              v-model="total_anggaran"
              type="number"
              required
              name="total_anggaran"
              placeholder="Total Anggaran"
              :disabled="(saktiFailed === true) ? false : true"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          v-else
          v-b-tooltip.hover.top="'Total Anggaran Program sesuai dengan Renja'"
          :label="`Total Anggaran ${(total_anggaran !== '') ? ': Rp. ' + Number(total_anggaran).toLocaleString() : ''}`"
          label-for="vi-total_anggaran"
        >
          <validation-provider
            #default="{ errors }"
            name="Total Anggaran"
            rules="required"
          >
            <b-form-input
              id="vi-total_anggaran"
              v-model="total_anggaran"
              type="number"
              required
              name="total_anggaran"
              placeholder="Total Anggaran"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-if="useSakti"
          v-b-tooltip.hover.top="'Kode Mata Anggaran Program sesuai dengan Renja'"
          label="Kode Mata Anggaran"
          label-for="vi-kode_anggaran"
        >
          <validation-provider
            #default="{ errors }"
            name="Kode Mata Anggaran"
            rules="required"
          >
            <b-form-input
              id="vi-kode_anggaran"
              v-model="kode_anggaran"
              required
              name="kode_anggaran"
              placeholder="Kode Mata Anggaran"
              :disabled="(saktiFailed === true) ? false : true"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          v-else
          v-b-tooltip.hover.top="'Kode Mata Anggaran Program sesuai dengan Renja'"
          label="Kode Mata Anggaran"
          label-for="vi-kode_anggaran"
        >
          <validation-provider
            #default="{ errors }"
            name="Kode Mata Anggaran"
            rules="required"
          >
            <b-form-input
              id="vi-kode_anggaran"
              v-model="kode_anggaran"
              required
              name="kode_anggaran"
              placeholder="Kode Mata Anggaran"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <small class="text-info">contoh : [Kode Dept].[Kode Unit].[Kode Kegiatan].[Kode KRO].[Kode RO]</small>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-if="useSakti"
          v-b-tooltip.hover.top="'Apakah Program Merupakan Prioritas Nasional?'"
          label="Prioritas Nasional"
          label-for="vi-prioritas_nasional"
        >
          <validation-provider
            #default="{ errors }"
            name="Prioritas Nasional"
            rules="required"
          >
            <v-select
              id="vi-prioritas_nasional"
              v-model="prioritas_nasional"
              required
              name="prioritas_nasional"
              :options="daftarNationalPriority"
              placeholder="Pilih Salah Satu"
              label="text"
              :disabled="(saktiFailed === true) ? false : true"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          v-else
          v-b-tooltip.hover.top="'Apakah Program Merupakan Prioritas Nasional?'"
          label="Prioritas Nasional"
          label-for="vi-prioritas_nasional"
        >
          <validation-provider
            #default="{ errors }"
            name="Prioritas Nasional"
            rules="required"
          >
            <v-select
              id="vi-prioritas_nasional"
              v-model="prioritas_nasional"
              required
              name="prioritas_nasional"
              :options="daftarNationalPriority"
              placeholder="Pilih Salah Satu"
              label="text"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Jenis Belanja Program sesuai dengan Renja'"
          label="Jenis Belanja"
          label-for="vi-master_jenis_belanja_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Jenis Belanja"
            rules="required"
          >
            <v-select
              id="vi-master_jenis_belanja_id"
              v-model="master_jenis_belanja_id"
              required
              name="master_jenis_belanja_id"
              :options="referenceData.ref_jenis_belanja"
              placeholder="Pilih Salah Satu"
              label="nama"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Jenis Pengadaan Program sesuai dengan Renja'"
          label="Jenis Pengadaan"
          label-for="vi-master_jenis_pengadaan_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Jenis Pengadaan"
            rules="required"
          >
            <v-select
              id="vi-master_jenis_pengadaan_id"
              v-model="master_jenis_pengadaan_id"
              required
              name="master_jenis_pengadaan_id"
              :options="referenceData.ref_jenis_pengadaan"
              placeholder="Pilih Salah Satu"
              label="nama"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Sumber Dana Program sesuai dengan Renja'"
          label="Sumber Dana"
          label-for="vi-master_sumber_dana_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Sumber Dana"
            rules="required"
          >
            <v-select
              id="vi-master_sumber_dana_id"
              v-model="master_sumber_dana_id"
              required
              name="master_sumber_dana_id"
              :options="referenceData.ref_sumber_dana"
              placeholder="Pilih Salah Satu"
              label="nama"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BFormInput, BRow, BCol, BFormGroup, VBTooltip, BAlert,
  BLink, BInputGroup, BInputGroupAppend, BButton,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { required } from '@validations'

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BLink,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    ValidationProvider,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    appData: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      year_budget: localStorage.getItem('tahunAnggaran'),
      userData: getUserData(),
      referenceData: {
        ref_jenis_belanja: [],
        ref_jenis_pengadaan: [],
        ref_pic: [],
        ref_sumber_dana: [],
      },
      clearance_id: '',
      data_utama_id: '',
      nama_unit_kerja: '',
      nama_kegiatan_sendiri: '',
      nama_kode_model_referensi: '',
      nama_kegiatan: '',
      unit_kerja: '',
      pic_id: '',
      pic_name: '',
      pic_number: '',
      kode_model_referensi: '',
      rincian_output: '',
      tahun_anggaran: '',
      total_anggaran: '',
      kode_anggaran: '',
      prioritas_nasional: '',
      penanggungJawabModel: '',
      daftarPenanggungJawabModel: {
        pic_id: 'new', label: 'Tambah Data Penanggung Jawab Baru', pic_number: '',
      },
      daftarNationalPriority: [
        { value: '1', text: 'Ya' },
        { value: '0', text: 'Tidak' },
      ],
      daftarKroTambahan: [
        { kdunit: 'other', kdoutput: 'BMA', label: 'BMA. Data dan Informasi Publik' },
        { kdunit: 'other', kdoutput: 'QMA', label: 'QMA. Data dan Informasi Publik' },
        { kdunit: 'other', kdoutput: 'CAN', label: 'CAN. Sarana dan Prasarana Bidang Teknologi Informasi dan Komunikasi' },
        { kdunit: 'other', kdoutput: 'RAN', label: 'RAN. Sarana dan Prasarana Bidang Teknologi Informasi dan Komunikasi' },
        { kdunit: 'other', kdoutput: 'CBT', label: 'CBT. Sarana dan Prasarana Bidang Teknologi Informasi dan Komunikasi' },
        { kdunit: 'other', kdoutput: 'RBT', label: 'RBT. Sarana dan Prasarana Bidang Teknologi Informasi dan Komunikasi' },
        { kdunit: 'other', kdoutput: 'CCL', label: 'CCL. OM Sarana Bidang Teknologi Informasi dan Komunikasi' },
        { kdunit: 'other', kdoutput: 'RCL', label: 'RCL. OM Sarana Bidang Teknologi Informasi dan Komunikasi' },
        { kdunit: 'other', kdoutput: 'CDS', label: 'CDS. OM Prasarana Bidang Teknologi Informasi dan Komunikasi' },
        { kdunit: 'other', kdoutput: 'RDS', label: 'RDS. OM Prasarana Bidang Teknologi Informasi dan Komunikasi' },
        { kdunit: 'other', kdoutput: 'FAB', label: 'FAB. Sistem Informasi Pemerintahan' },
        { kdunit: 'other', kdoutput: 'UAB', label: 'UAB. Sistem Informasi Pemerintahan' },
      ],
      master_jenis_belanja_id: '',
      master_jenis_pengadaan_id: '',
      master_sumber_dana_id: '',
      useSakti: true,
      fokusRo: false,
      saktiLoad: false,
      saktiFailed: false,
      saktiFailedMsg: '',
      saktiFailedLink: false,
      saktiToken: '',
      saktiUnit: [],
      saktiKegiatan: [],
      saktiKro: [],
      saktiRo: [],
      saktiItem: {},
    }
  },
  watch: {
    actionStep() {
      this.saveDataUtama()
    },
  },
  created() {
    this.clearance_id = this.$route.params.id
    this.getClearance()
  },
  mounted() {
    this.getReference()
  },
  methods: {
    unitReset() {
      this.unit_kerja = ''
      this.nama_unit_kerja = ''
      this.nama_kegiatan_sendiri = ''
      this.nama_kode_model_referensi = ''
      this.nama_kegiatan = ''
      this.kode_model_referensi = ''
      this.saktiKro = []
      this.useSakti = true
      this.saktiGetUnit()
    },
    namaReset() {
      this.nama_kegiatan = ''
      this.nama_kegiatan_sendiri = ''
      this.nama_kode_model_referensi = ''
      this.kode_model_referensi = ''
      this.saktiKro = []
      this.useSakti = true
      this.saktiGetUnit()
    },
    kroReset() {
      this.useSakti = true
      this.nama_kode_model_referensi = ''
      this.fokusRo = 'kro'
      this.saktiGetKegiatan()
    },
    getClearance() {
      this.$http.get('/clearance', {
        params: {
          token: localStorage.getItem('userToken'),
          clearance_id: this.clearance_id,
          tahun_anggaran: this.year_budget,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            const cdata = res.data.data
            this.tahun_anggaran = cdata.year_budget
            this.splpSaktiGetToken()
          }
        })
    },
    setContact() {
      this.pic_name = this.penanggungJawabModel.name
      this.pic_number = this.penanggungJawabModel.number
    },
    splpSaktiGetToken() {
      this.saktiLoad = true
      document.getElementById('loading-bg').style.display = 'block'
      this.$http.get(`/sakti/token?token=${localStorage.getItem('userToken')}`)
        .then(res => {
          if (res.data.status === 'success') {
            this.saktiToken = res.data.data.access_token
            this.saktiGetUnit()
          } else {
            this.saktiFailed = true
            this.saktiLoad = false
            document.getElementById('loading-bg').style.display = 'none'
          }
        })
        .catch(error => {
          this.saktiFailedMsg = error
          this.saktiFailed = true
          this.saktiLoad = false
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
    saktiGetUnit() {
      this.$splp.get('/ega', {
        headers: {
          Authorization: `Bearer ${this.saktiToken}`,
        },
        params: {
          year: this.tahun_anggaran,
          table: 'unit',
          kddept: this.userData.instansi_kddept,
        },
      })
        .then(res => {
          if (res.data.success === true) {
            this.saktiUnit = []
            const pics = []
            res.data.data.map(value => {
              const pic = value
              pic.label = `${value.kddept}.${value.kdunit}. ${value.nmunit}`
              pics.push(pic)
              return true
            })
            if (pics.length === 0) {
              this.saktiFailed = true
              this.saktiFailedLink = true
              this.useSakti = false
              this.saktiFailedMsg = 'Data Kegiatan tidak ditemukan di Aplikasi Sakti, hubungi Admin atau isi form pada '
            } else {
              this.saktiUnit = pics
            }
            const lainnya = {
              kdunit: 'other',
              label: 'Unit Kerja lain (Tambah Unit)',
            }
            this.saktiUnit.push(lainnya)
            this.saktiLoad = false
            document.getElementById('loading-bg').style.display = 'none'
          } else {
            this.saktiFailed = true
            this.saktiLoad = false
            this.useSakti = false
            document.getElementById('loading-bg').style.display = 'none'
          }
        })
        .catch(error => {
          this.saktiFailedMsg = error
          this.saktiFailed = true
          this.saktiLoad = false
          this.useSakti = false
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
    saktiGetKegiatan() {
      if (this.unit_kerja !== null) {
        if (this.unit_kerja !== '' && this.unit_kerja.kdunit !== 'other') {
          this.useSakti = true
          this.nama_unit_kerja = this.unit_kerja.label
          document.getElementById('loading-bg').style.display = 'block'
          const params = {
            year: this.tahun_anggaran,
            table: 'giat',
            kddept: this.userData.instansi_kddept,
          }
          if (this.unit_kerja !== '') {
            params.kdunit = this.unit_kerja.kdunit
          }
          this.$splp.get('/ega', {
            headers: {
              Authorization: `Bearer ${this.saktiToken}`,
            },
            params,
          })
            .then(res => {
              if (res.data.success === true) {
                this.saktiFailed = false
                this.saktiKegiatan = []
                this.nama_kegiatan = ''
                const pics = []
                res.data.data.map(value => {
                  const pic = value
                  pic.label = `${value.kdgiat}. ${value.nmgiat}`
                  pics.push(pic)
                  return true
                })
                this.saktiKegiatan = pics
                const lainnya = {
                  kdgiat: 'other',
                  label: 'Kegiatan Lainnya (Tambah Kegiatan Baru)',
                }
                this.saktiKegiatan.push(lainnya)
                document.getElementById('loading-bg').style.display = 'none'
              } else {
                this.saktiFailed = true
                this.useSakti = false
                this.saktiLoad = false
                document.getElementById('loading-bg').style.display = 'none'
              }
            })
            .catch(error => {
              this.saktiFailedMsg = error
              this.saktiFailed = true
              this.useSakti = false
              this.saktiLoad = false
              document.getElementById('loading-bg').style.display = 'none'
            })
        } else {
          this.useSakti = false
          const pics2 = []
          this.daftarKroTambahan.map(value => {
            const pic = value
            pics2.push(pic)
            return true
          })
          this.saktiKro = pics2

          this.nama_unit_kerja = ''
          this.fokusRo = 'unit'
          this.kode_model_referensi = ''
          this.rincian_output = ''
          this.total_anggaran = ''
          this.kode_anggaran = ''
          this.prioritas_nasional = ''
        }
      } else {
        this.useSakti = true
        this.saktiGetUnit()
        this.unit_kerja = ''
        this.nama_kegiatan = ''
        this.kode_model_referensi = ''
        this.rincian_output = ''
        this.total_anggaran = ''
        this.saktiItem = {}
        this.saktiUnit = []
        this.saktiKegiatan = []
        this.saktiKro = []
        this.saktiRo = []
      }
    },
    saktiGetKro() {
      if (this.nama_kegiatan !== null) {
        if (this.nama_kegiatan !== '' && this.nama_kegiatan.kdgiat !== 'other') {
          this.useSakti = true
          this.nama_kegiatan_sendiri = this.nama_kegiatan.label
          document.getElementById('loading-bg').style.display = 'block'
          const params = {
            year: this.tahun_anggaran,
            table: 'output',
            kddept: this.userData.instansi_kddept,
            kdunit: this.unit_kerja.kdunit,
          }
          if (this.nama_kegiatan !== '') {
            params.kdgiat = this.nama_kegiatan.kdgiat
          }
          this.$splp.get('/ega', {
            headers: {
              Authorization: `Bearer ${this.saktiToken}`,
            },
            params,
          })
            .then(res => {
              if (res.data.success === true) {
                this.saktiFailed = false
                this.saktiKro = []
                this.kode_model_referensi = ''
                const pics = []
                res.data.data.map(value => {
                  const pic = value
                  pic.label = `${value.kdgiat}.${value.kdoutput}. ${value.nmoutput}`
                  pics.push(pic)
                  return true
                })
                this.saktiKro = pics
                const lainnya = {
                  kdunit: 'other',
                  label: 'KRO lainnya (Sesuai Renja)',
                }
                this.saktiKro.push(lainnya)
                document.getElementById('loading-bg').style.display = 'none'
              } else {
                this.saktiFailed = true
                this.useSakti = false
                this.saktiLoad = false
                document.getElementById('loading-bg').style.display = 'none'
              }
            })
            .catch(error => {
              this.saktiFailedMsg = error
              this.saktiFailed = true
              this.useSakti = false
              this.saktiLoad = false
              document.getElementById('loading-bg').style.display = 'none'
            })
        } else {
          this.useSakti = false
          this.saktiKro = []
          this.fokusRo = 'giat'
          this.kode_model_referensi = ''
          this.nama_kode_model_referensi = ''
          this.rincian_output = ''
          this.total_anggaran = ''
          this.kode_anggaran = ''
          this.prioritas_nasional = ''
        }
      } else {
        this.useSakti = true
        this.nama_kegiatan = ''
        this.kode_model_referensi = ''
        this.nama_kode_model_referensi = ''
        this.rincian_output = ''
        this.total_anggaran = ''
        this.saktiItem = {}
        this.saktiKegiatan = []
        this.saktiKro = []
        this.saktiRo = []
        this.saktiGetKegiatan()
      }
    },
    saktiGetRo() {
      if (this.kode_model_referensi !== null) {
        if (this.kode_model_referensi !== '' && this.kode_model_referensi.kdunit !== 'other') {
          this.useSakti = true
          document.getElementById('loading-bg').style.display = 'block'
          const params = {
            year: this.tahun_anggaran,
            table: 'soutput',
            kddept: this.userData.instansi_kddept,
            kdunit: this.unit_kerja.kdunit,
            kdgiat: this.nama_kegiatan.kdgiat,
          }
          if (this.kode_model_referensi !== '') {
            params.kdoutput = this.kode_model_referensi.kdoutput
          }
          this.$splp.get('/ega', {
            headers: {
              Authorization: `Bearer ${this.saktiToken}`,
            },
            params,
          })
            .then(res => {
              if (res.data.success === true) {
                this.saktiFailed = false
                this.saktiRo = []
                this.rincian_output = ''
                const pics = []
                res.data.data.map(value => {
                  const pic = value
                  pic.label = `${value.kdgiat}.${value.kdoutput}.${value.kdsoutput}. ${value.nmsoutput}`
                  pics.push(pic)
                  return true
                })
                this.saktiRo = pics
                document.getElementById('loading-bg').style.display = 'none'
              } else {
                this.saktiFailed = true
                this.useSakti = false
                this.saktiLoad = false
                document.getElementById('loading-bg').style.display = 'none'
              }
            })
            .catch(error => {
              this.saktiFailedMsg = error
              this.saktiFailed = true
              this.useSakti = false
              this.saktiLoad = false
              document.getElementById('loading-bg').style.display = 'none'
            })
        } else {
          this.useSakti = false
          this.fokusRo = 'kro'
          this.rincian_output = ''
          this.total_anggaran = ''
          this.kode_anggaran = ''
          this.prioritas_nasional = ''
        }
      } else {
        this.kode_model_referensi = ''
        this.nama_kode_model_referensi = ''
        this.rincian_output = ''
        this.total_anggaran = ''
        this.saktiItem = {}
        this.saktiKro = []
        this.saktiRo = []
        this.saktiGetKro()
      }
    },
    saktiGetItem() {
      if (this.rincian_output !== null) {
        document.getElementById('loading-bg').style.display = 'block'
        this.useSakti = true
        if (this.rincian_output.kdpn !== '0') {
          this.prioritas_nasional = { value: '1', text: 'Ya' } // Data Prioritas Nasional
        } else {
          this.prioritas_nasional = { value: '0', text: 'Tidak' } // Data Prioritas Nasional
        }

        const params = {
          year: this.tahun_anggaran,
          table: 'item',
          kddept: this.userData.instansi_kddept,
          kdunit: this.unit_kerja.kdunit,
          kdgiat: this.nama_kegiatan.kdgiat,
          kdoutput: this.kode_model_referensi.kdoutput,
          kdsoutput: this.rincian_output.kdsoutput,
        }
        this.$splp.get('/ega', {
          headers: {
            Authorization: `Bearer ${this.saktiToken}`,
          },
          params,
        })
          .then(res => {
            if (res.data.success === true) {
              this.saktiFailed = false
              res.data.data.map(value => {
                this.saktiItem = value
                return true
              })
              if (Object.keys(this.saktiItem).length !== 0) {
                this.total_anggaran = this.saktiItem.jmlpagu
                this.kode_anggaran = `${this.saktiItem.kddept}.${this.saktiItem.kdunit}.${this.saktiItem.kdgiat}.${this.saktiItem.kdoutput}.${this.saktiItem.kdsoutput}`
              }
              document.getElementById('loading-bg').style.display = 'none'
            } else {
              this.saktiFailed = true
              this.useSakti = false
              this.saktiLoad = false
              document.getElementById('loading-bg').style.display = 'none'
            }
          })
          .catch(error => {
            this.saktiFailedMsg = error
            this.saktiFailed = true
            this.useSakti = false
            this.saktiLoad = false
            document.getElementById('loading-bg').style.display = 'none'
          })
      } else {
        this.rincian_output = ''
        this.total_anggaran = ''
        this.saktiItem = {}
        this.saktiKro = []
        this.saktiRo = []
        this.saktiGetRo()
      }
    },
    getReference() {
      this.$http.get('/clearance/core-data/references', {
        params: {
          token: localStorage.getItem('userToken'),
          tahun_anggaran: this.year_budget,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data
            // this.referenceData.ref_pic.unshift(this.daftarPenanggungJawabModel)
            if (res.data.data.ref_pic) {
              const pics = [this.daftarPenanggungJawabModel]
              res.data.data.ref_pic.map(value => {
                const pic = value
                pic.label = `${value.nama} (${value.pic_number})`
                pics.push(pic)
                return true
              })
              this.referenceData.ref_pic = pics
            }
          }
        })
    },
    saveDataUtama() {
      if (this.pic_name === '' || this.pic_number === '') {
        const data = {
          status: false,
          msg: 'Data Penanggung Jawab Tidak Boleh Kosong',
        }
        this.$emit('step-completed', data)
      } else {
        document.getElementById('loading-bg').style.display = 'block'
        const metaUtama = {
          instansi_id: this.userData.instansi_id,
          clearance_id: this.clearance_id,
          pic_name: this.pic_name,
          pic_number: this.pic_number,
          tahun_anggaran: this.tahun_anggaran,
          total_anggaran: this.total_anggaran,
          kode_anggaran: this.kode_anggaran,
          prioritas_nasional: this.prioritas_nasional.value,
          jenis_belanja_id: Number(this.master_jenis_belanja_id.jenis_belanja_id),
          jenis_pengadaan_id: Number(this.master_jenis_pengadaan_id.jenis_pengadaan_id),
          sumber_dana_id: Number(this.master_sumber_dana_id.sumber_dana_id),
        }
        if (this.useSakti) {
          metaUtama.nama_kegiatan = this.nama_kegiatan.label
          metaUtama.unit_kerja = this.unit_kerja.label
          metaUtama.kode_model_referensi = this.kode_model_referensi.label
          metaUtama.rincian_output = this.rincian_output.label
        } else {
          metaUtama.nama_kegiatan = this.nama_kegiatan_sendiri
          metaUtama.unit_kerja = this.nama_unit_kerja
          metaUtama.kode_model_referensi = this.nama_kode_model_referensi
          metaUtama.rincian_output = this.rincian_output
        }
        if (this.penanggungJawabModel.pic_id !== 'new') {
          metaUtama.pic_id = this.penanggungJawabModel.pic_id
        }
        if (this.data_utama_id) {
          metaUtama.data_utama_id = this.data_utama_id
        }
        this.$http.post('/clearance/core-data', metaUtama, {
          params: {
            token: localStorage.getItem('userToken'),
          },
        })
          .then(res => {
            const data = {
              status: true,
              msg: res.data.data,
              sakti: this.saktiItem,
              useSakti: this.useSakti,
            }
            this.data_utama_id = res.data.data.data_utama_id
            this.$emit('step-completed', data)
            document.getElementById('loading-bg').style.display = 'none'
          })
          .catch(error => {
            const data = {
              status: false,
              msg: error.response.data.error,
            }
            this.$emit('step-completed', data)
            document.getElementById('loading-bg').style.display = 'none'
          })
      }
    },
  },
}
</script>

<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <h5 class="mb-0">
          Data &amp; Informasi
        </h5>
        <small class="text-muted">Arsitektur Domain Data dan Informasi SPBE.</small>
      </b-col>
      <b-col md="12">
        <b-form-group
          v-b-tooltip.hover.top="'Arsitektur Domain Data dan Informasi sesuai dengan Renja'"
          label-for="vi-data_utama_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Pilih Nama Data dan Informasi atau Tambah Baru"
            rules="required"
          >
            <v-select
              id="vi-master_data"
              v-model="master_data"
              required
              name="master_data"
              :options="referenceData.data_informasi_list"
              placeholder="Pilih Nama Data dan Informasi atau Tambah Baru"
              label="label"
              @input="setSelected()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="master_data !== ''">
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
          label="Data Pokok"
          label-for="vi-data_basic_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Data Pokok"
            rules="required"
          >
            <v-select
              id="vi-data_basic_id"
              v-model="data_basic_id"
              required
              name="data_basic_id"
              :options="referenceData.ref_data_pokok"
              placeholder="Data Pokok"
              label="nama"
              @input="getDataTematik()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
          label="Data Tematik"
          label-for="vi-data_tematic_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Data Tematik"
            rules="required"
          >
            <v-select
              id="vi-data_tematic_id"
              v-model="data_tematic_id"
              required
              name="data_tematic_id"
              :options="daftarDataTematik"
              placeholder="Data Tematik"
              label="nama"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan data yang dikelola oleh unit kerja/perangkat daerah.'"
          label="Nama Data"
          label-for="vi-nama"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Data"
            rules="required"
          >
            <b-form-input
              id="vi-nama"
              v-model="nama"
              required
              name="nama"
              placeholder="Nama Data"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan penjelasan apakah terdapat interoperabilitas data dengan data yang lain.'"
          label="Interoperabilitas Data"
          label-for="vi-data_interoperability_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Interoperabilitas Data"
            rules="required"
          >
            <v-select
              id="vi-data_interoperability_id"
              v-model="data_interoperability_id"
              required
              name="data_interoperability_id"
              :options="daftarDataInteroperability"
              placeholder="Interoperabilitas Data"
              label="nama"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan klasifikasi data berdasarkan bentuk datanya.'"
          label="Sifat Data"
          label-for="vi-data_status_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Sifat Data"
            rules="required"
          >
            <v-select
              id="vi-data_status_id"
              v-model="data_status_id"
              required
              name="data_status_id"
              :options="referenceData.ref_sifat_data"
              placeholder="Sifat Data"
              label="nama"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan penjelasan tujuan dari pemanfaatan data.'"
          label="Tujuan Data"
          label-for="vi-tujuan"
        >
          <validation-provider
            #default="{ errors }"
            name="Tujuan Data"
            rules="required"
          >
            <b-form-textarea
              id="vi-tujuan"
              v-model="tujuan"
              rows="4"
              required
              name="tujuan"
              placeholder="Tujuan Data"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          v-b-tooltip.hover.left="'Merupakan tanggal mulai berlakunya data.'"
          label="Awal Validitas Data"
          label-for="vi-valid_start"
        >
          <validation-provider
            #default="{ errors }"
            name="Awal Validitas Data"
            rules="required"
          >
            <b-form-datepicker
              id="vi-valid_start"
              v-model="valid_start"
              :max="valid_end"
              :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
              size="sm"
              required
              name="valid_start"
              placeholder="Awal Validitas Data"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          v-b-tooltip.hover.left="'Merupakan tanggal akhir berlakunya data.'"
          label="Akhir Validitas Data"
          label-for="vi-valid_end"
        >
          <validation-provider
            #default="{ errors }"
            name="Akhir Validitas Data"
            rules="required"
          >
            <b-form-datepicker
              id="vi-valid_end"
              v-model="valid_end"
              :min="valid_start"
              :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
              size="sm"
              required
              name="valid_end"
              placeholder="Akhir Validitas Data"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan penanggung jawab dari data.'"
          label="Penanggung Jawab"
          label-for="vi-pic_name"
        >
          <validation-provider
            #default="{ errors }"
            name="Penanggung Jawab"
            rules="required"
          >
            <b-form-input
              id="vi-pic_name"
              v-model="pic_name"
              required
              name="pic_name"
              placeholder="Penanggung Jawab"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan penjelasan atau keterangan dari data, terutama mengenai ciri khas dari data tersebut.'"
          label="Uraian Data"
          label-for="vi-deskripsi"
        >
          <validation-provider
            #default="{ errors }"
            name="Uraian Data"
            rules="required"
          >
            <b-form-textarea
              id="vi-deskripsi"
              v-model="deskripsi"
              rows="4"
              required
              name="deskripsi"
              placeholder="Uraian Data"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>

    <div v-if="errorMsg">
      <b-alert
        variant="danger"
        show
      >
        <h4 class="alert-heading">
          Error Found
        </h4>
        <div class="alert-body">
          <ul v-if="typeof errorMsg === 'object'">
            <li
              v-for="(item, index) in errorMsg"
              :key="index"
            >
              {{ index }}:
              <span
                v-for="(child, indexs) in item"
                :key="indexs"
              >
                {{ child }}
              </span>
            </li>
          </ul>
          <span v-else>{{ errorMsg }}</span>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BFormInput, BRow, BCol, BFormDatepicker, BFormGroup, BFormTextarea,
  BAlert, VBTooltip,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { required } from '@validations'

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BFormDatepicker,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    coreData: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
    clearanceId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      userData: getUserData(),
      referenceData: {
        data_informasi_list: [],
        ref_data_pokok: [],
        ref_data_topic: [],
        ref_data_tematik: [],
        ref_sifat_data: [],
      },
      data_utama_id: '',
      master_data: '',
      data_informasi_id: '',
      dataData: '',
      data_basic_id: '',
      data_tematic_id: '',
      nama: '',
      data_topic_id: '',
      data_interoperability_id: '',
      data_status_id: '',
      tujuan: '',
      valid_start: '',
      valid_end: '',
      pic_name: '',
      deskripsi: '',
      daftarMasterDataInformasi: { data_pokok_id: 'new', label: 'Pilih Nama Data Atau Tambah Data dan Informasi Baru' },
      daftarDataInteroperability: [
        { value: '0', nama: 'Tidak' },
        { value: '1', nama: 'Ya' },
      ],
      daftarDataTematik: [],
      errorMsg: '',
    }
  },
  watch: {
    actionStep() {
      this.saveInformasi()
    },
  },
  mounted() {
    this.getReference()
  },
  methods: {
    checkFile() {
      if (this.file1.length !== 0) {
        const notif = []
        let not = false
        for (let i = 0; i < this.file1.length; i += 1) {
          const alert = []
          if (this.file1[i].size > 100 * 1024 * 1024) {
            not = true
            alert.push(`${this.file1[i].name}: Ukuran Dokumen Terlalu Besar`)
          } if (this.file1[i].type !== 'application/vnd.ms-excel' || this.file2[i].type !== 'text/csv') {
            not = true
            alert.push(`${this.file1[i].name}: Jenis Dokumen Tidak Sesuai`)
          }
          if (not) {
            notif.push(alert)
          }
        }
        this.errorMsg = (notif.length !== 0) ? notif : ''
      }
    },
    checkFile2() {
      if (this.file2.length !== 0) {
        const notif = []
        let not = false
        for (let i = 0; i < this.file2.length; i += 1) {
          const alert = []
          if (this.file2[i].size > 100 * 1024 * 1024) {
            not = true
            alert.push(`${this.file2[i].name}: Ukuran Dokumen Terlalu Besar`)
          } if (this.file2[i].type !== 'application/pdf') {
            not = true
            alert.push(`${this.file2[i].name}: Jenis Dokumen Tidak Sesuai`)
          }
          if (not) {
            notif.push(alert)
          }
        }
        this.errorMsg = (notif.length !== 0) ? notif : ''
      }
    },
    checkFile3() {
      if (this.file3) {
        if (this.file3.size > 100 * 1024 * 1024) {
          this.errorMsg = `${this.file3.name}: Ukuran Dokumen Terlalu Besar`
        } if (this.file1.type !== 'application/pdf') {
          this.errorMsg = `${this.file3.name}: Jenis Dokumen Tidak Sesuai`
        } else {
          this.errorMsg = ''
        }
      }
    },
    getReference() {
      this.$http.get('/clearance/data-informasi/references', {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data
            // this.referenceData.data_informasi_list.unshift(this.daftarMasterDataInformasi)
            if (res.data.data.data_informasi_list) {
              const pics = [this.daftarMasterDataInformasi]
              res.data.data.data_informasi_list.map(value => {
                const pic = value
                pic.label = `${value.nama} / ${value.data_pokok} / ${value.data_tematik}`
                pics.push(pic)
                return true
              })
              this.referenceData.data_informasi_list = pics
            }
          }
        })
    },
    getDataTematik() {
      this.daftarDataTematik.splice(0)
      this.data_tematic_id = ''

      const filterVal = this.data_basic_id.data_pokok_id
      const refData = this.referenceData.ref_data_tematik
      refData.map(contact => {
        if (contact.data_pokok_id === filterVal) {
          this.daftarDataTematik.push(contact)
        }
        return true
      })
    },
    setSelected() {
      if (this.master_data.data_informasi_id !== 'new') {
        this.dataData = this.master_data

        this.nama = this.dataData.nama
        this.tujuan = this.dataData.tujuan
        this.valid_start = this.dataData.valid_start
        this.valid_end = this.dataData.valid_end
        this.pic_name = this.dataData.pic_name
        this.deskripsi = this.dataData.deskripsi

        this.data_basic_id = {
          data_pokok_id: this.dataData.data_pokok_id,
          nama: this.dataData.data_pokok,
        }
        this.data_tematic_id = {
          data_tematik_id: this.dataData.data_tematik_id,
          nama: this.dataData.data_tematik,
        }
        this.data_status_id = {
          sifat_data_id: this.dataData.sifat_data_id,
          nama: this.dataData.sifat_data,
        }
        this.data_interoperability_id = this.daftarDataInteroperability[this.dataData.interoperability_id]
      } else {
        this.dataData = ''

        this.nama = ''
        this.tujuan = ''
        this.valid_start = ''
        this.valid_end = ''
        this.pic_name = ''
        this.deskripsi = ''

        this.data_basic_id = ''
        this.data_tematic_id = ''
        this.data_status_id = ''
        this.data_interoperability_id = ''
      }
    },
    saveInformasi() {
      document.getElementById('loading-bg').style.display = 'block'
      const metaUtama = {
        data_utama_id: this.clearanceId,
        instansi_id: this.userData.instansi_id,
        data_pokok_id: this.data_basic_id ? this.data_basic_id.data_pokok_id : 1,
        data_tematik_id: this.data_tematic_id ? this.data_tematic_id.data_tematik_id : 1,
        nama: this.nama,
        interoperability_id: this.data_interoperability_id.value,
        sifat_data_id: this.sifat_data_id ? this.data_status_id.sifat_data_id : 1,
        tujuan: this.tujuan,
        valid_start: this.valid_start,
        valid_end: this.valid_end,
        pic_name: this.pic_name,
        deskripsi: this.deskripsi,
      }
      if (this.data_informasi_id !== '') {
        metaUtama.data_informasi_id = this.data_informasi_id
      }
      this.$http.post('/clearance/data-informasi', metaUtama, {
        params: {
          token: localStorage.getItem('userToken'),
          clearance_id: this.$route.params.id,
          data_utama_id: this.clearanceId,
        },
      })
        .then(res => {
          const data = {
            status: true,
            msg: res.data.data,
          }
          this.data_informasi_id = data.msg.data_informasi_id
          data.msg.data_utama_id = this.clearanceId
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
        .catch(error => {
          const data = {
            status: false,
            msg: error.response.data.error,
          }
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
  },
}
</script>

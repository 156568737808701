<template>
  <section id="tambah-surat-permohonan">
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Simpan"
      back-button-text="Sebelumnya"
      class="vertical-steps steps-transparent mb-3"
      @on-complete="submitForm"
      @on-change="stepCheck"
    >

      <!-- Data Utama tab -->
      <!-- :before-change="validateDataUtama" -->
      <tab-content
        title="Data Utama"
        :before-change="validateDataUtama"
      >
        <validation-observer ref="formDataUtama">
          <b-form>
            <tambah-data-utama
              :action-step="stepDataUtama"
              @step-completed="stepCompleted"
            />
          </b-form>
        </validation-observer>
      </tab-content>

      <!-- Proses Bisnis tab -->
      <!-- :before-change="validateBisnis" -->
      <tab-content
        title="Proses Bisnis"
        :before-change="validateBisnis"
      >
        <validation-observer ref="formBisnis">
          <b-form>
            <tambah-bisnis
              :clearance-id="data_utama_id"
              :sakti="sakti"
              :action-step="stepBisnis"
              @step-completed="stepCompleted"
            />
          </b-form>
        </validation-observer>
      </tab-content>

      <!-- Layanan -->
      <!-- :before-change="validateLayanan" -->
      <tab-content
        title="Layanan"
        :before-change="validateLayanan"
      >
        <validation-observer ref="formLayanan">
          <b-form>
            <tambah-layanan
              :clearance-id="data_utama_id"
              :sakti="sakti"
              :action-step="stepLayanan"
              @step-completed="stepCompleted"
            />
          </b-form>
        </validation-observer>
      </tab-content>

      <!-- Data & Informasi -->
      <!-- :before-change="validateDataInformasi" -->
      <tab-content
        title="Data &amp; Informasi"
        :before-change="validateDataInformasi"
      >
        <validation-observer ref="formInformasi">
          <b-form>
            <tambah-data-informasi
              :clearance-id="data_utama_id"
              :action-step="stepInformasi"
              @step-completed="stepCompleted"
            />
          </b-form>
        </validation-observer>
      </tab-content>

      <!-- Aplikasi -->
      <!-- :before-change="validateAplikasi" -->
      <tab-content
        title="Aplikasi"
        :before-change="validateAplikasi"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Aplikasi
            </h5>
            <small class="text-muted">Arsitektur Domain Aplikasi SPBE untuk seluruh Aplikasi yang terkait dengan kegiatan.</small>
          </b-col>
        </b-row>

        <div
          v-for="(item, index) in totalAplikasi"
          :id="`aplikasi-${item.id}`"
          :key="item.id"
          ref="row"
          :title="`Data Aplikasi ${item.id}`"
        >
          <div class="border-bottom mb-0 card-text p-0 d-flex">
            <b-button
              v-b-toggle
              :href="`#collapse-app-${item.id}`"
              variant="flat-secondary"
              class="text-left flex-grow-1"
              @click.prevent
            >
              Data Aplikasi {{ item.id }}
            </b-button>
            <b-button
              variant="flat-danger"
              @click="removeAplikasi(index)"
            >
              <feather-icon
                icon="XIcon"
              />
            </b-button>
          </div>
          <b-collapse
            :id="`collapse-app-${item.id}`"
            visible
            class="mb-1"
          >
            <tambah-aplikasi
              :key="`app_${item.id}`"
              :core-data="clearanceData"
              :new-index="index"
              :app-data="item"
              :clearance-id="data_utama_id"
              :action-step="stepAplikasi"
              @step-completed="stepCompleted"
            />
          </b-collapse>
        </div>

        <b-row>
          <b-col
            md="6"
            class="mb-1"
          >
            <b-button
              variant="success"
              class="mt-0 mt-md-2"
              @click="repeateAplikasi"
            >
              <span>Tambah Aplikasi</span>
            </b-button>
          </b-col>
        </b-row>
      </tab-content>

      <!-- Infrastruktur -->
      <!-- :before-change="validateInfra" -->
      <tab-content
        title="Infrastruktur"
        :before-change="validateInfra"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Infrastruktur
            </h5>
            <small class="text-muted">Arsitektur Domain Infrastruktur SPBE untuk seluruh Infrastruktur yang terkait dengan kegiatan.</small>
          </b-col>
        </b-row>
        <div
          v-for="(item, index) in totalInfra"
          :id="`infra-${item.id}`"
          :key="item.id"
          ref="row"
          :title="`Data Infrastruktur ${item.id}`"
        >
          <div class="border-bottom mb-0 card-text p-0 d-flex">
            <b-button
              v-b-toggle
              :href="`#collapse-infra-${item.id}`"
              variant="flat-secondary"
              class="text-left flex-grow-1"
              @click.prevent
            >
              Data Infrastruktur {{ item.id }}
            </b-button>
            <b-button
              variant="flat-danger"
              @click="removeInfra(index)"
            >
              <feather-icon
                icon="XIcon"
              />
            </b-button>
          </div>
          <b-collapse
            :id="`collapse-infra-${item.id}`"
            class="mb-1"
            visible
          >
            <tambah-infrastruktur
              :key="`inf_${item.id}`"
              :core-data="clearanceData"
              :app-data="item"
              :clearance-id="data_utama_id"
              :action-step="stepInfra"
              @step-completed="stepCompleted"
            />
          </b-collapse>
        </div>

        <b-row>
          <b-col
            md="6"
            class="mb-1"
          >
            <b-button
              variant="success"
              class="mt-0 mt-md-2"
              @click="repeateInfra"
            >
              <span>Tambah Infrastruktur</span>
            </b-button>
          </b-col>
        </b-row>
      </tab-content>

      <!-- Keamanan -->
      <!-- :before-change="validateKeamanan" -->
      <tab-content
        title="Keamanan"
        :before-change="validateKeamanan"
      >
        <validation-observer ref="formKeamanan">
          <b-form>
            <tambah-keamanan
              :clearance-id="data_utama_id"
              :action-step="stepKeamanan"
              @step-completed="stepCompleted"
            />
          </b-form>
        </validation-observer>
      </tab-content>

      <div v-if="errorMsg">
        <b-alert
          variant="danger"
          show
        >
          <h4 class="alert-heading">
            Error Found
          </h4>
          <div class="alert-body">
            <ul v-if="typeof errorMsg === 'object'">
              <li
                v-for="(item, index) in errorMsg"
                :key="index"
              >
                {{ index }}:
                <span
                  v-for="(child, indexs) in item"
                  :key="indexs"
                >
                  {{ child }}
                </span>
              </li>
            </ul>
            <span v-else>{{ errorMsg }}</span>
          </div>
        </b-alert>
      </div>

    </form-wizard>
  </section>

</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BForm, BCollapse, BAlert, BButton, BRow, BCol, VBTooltip, VBToggle,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import TambahDataUtama from './tambah-kegiatan/TambahDataUtama.vue'
import TambahBisnis from './tambah-kegiatan/TambahBisnis.vue'
import TambahLayanan from './tambah-kegiatan/TambahLayanan.vue'
import TambahDataInformasi from './tambah-kegiatan/TambahDataInformasi.vue'
import TambahAplikasi from './tambah-kegiatan/TambahAplikasi.vue'
import TambahInfrastruktur from './tambah-kegiatan/TambahInfrastruktur.vue'
import TambahKeamanan from './tambah-kegiatan/TambahKeamanan.vue'

export default {
  components: {
    ValidationObserver,
    FormWizard,
    TabContent,
    BAlert,
    BForm,
    BRow,
    BCol,
    BButton,
    BCollapse,

    TambahDataUtama,
    TambahBisnis,
    TambahLayanan,
    TambahDataInformasi,
    TambahAplikasi,
    TambahInfrastruktur,
    TambahKeamanan,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      stepChange: 0,
      stepDataUtama: 0,
      stepBisnis: 0,
      stepLayanan: 0,
      stepInformasi: 0,
      stepAplikasi: 0,
      stepInfra: 0,
      stepKeamanan: 0,
      userData: '',
      letter_id: '',
      data_utama_id: 0,
      clearanceData: {},
      errorMsg: '',
      form: {},
      message: '',
      totalAplikasi: [],
      jumlahAplikasi: 0,
      totalInfra: [],
      jumlahInfra: 0,
      sakti: {},
    }
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role < 99 || role > 110) {
      next('/')
    } else {
      next()
    }
    if (!to.params.id) {
      next('/pengajuan/surat')
    } else {
      next()
    }
  },
  created() {
    localStorage.removeItem('dataBaru')
    this.userData = getUserData()
    this.letter_id = this.$route.params.id
    this.$http.get('/clearance', {
      params: {
        token: localStorage.getItem('userToken'),
        clearance_id: this.letter_id,
        tahun_anggaran: localStorage.getItem('tahunAnggaran'),
      },
    })
      .then(res => {
        if (res.data.status === 'success') {
          this.clearanceData = res.data.data
        }
      })
  },
  methods: {
    repeateAplikasi() {
      const jumlah = this.jumlahAplikasi + 1
      this.totalAplikasi.push({
        id: jumlah,
      })
      this.jumlahAplikasi = jumlah
    },
    removeAplikasi(data) {
      this.totalAplikasi.splice(data, 1)
    },
    repeateInfra() {
      const jumlah = this.jumlahInfra + 1
      this.totalInfra.push({
        id: jumlah,
      })
      this.jumlahInfra = jumlah
    },
    removeInfra(data) {
      this.totalInfra.splice(data, 1)
    },
    validateDataUtama() {
      this.$refs.formDataUtama.validate()
        .then(this.stepDataUtama += 1)

      return new Promise(resolve => {
        this.$on('step-validated', value => {
          if (!value.status) {
            this.errorMsg = value.msg
          } else {
            this.data_utama_id = value.msg.data_utama_id
            this.sakti = value.sakti
            this.errorMsg = ''
            localStorage.setItem('dataBaru', value.msg.data_utama_id)
          }
          resolve(value.status)
        })
      })
    },
    validateBisnis() {
      this.$refs.formBisnis.validate()
        .then(this.stepBisnis += 1)

      return new Promise(resolve => {
        this.$on('step-validated', value => {
          if (!value.status) {
            this.errorMsg = value.msg
          } else {
            this.data_utama_id = value.msg.data_utama_id
            this.sakti = value.sakti
            this.errorMsg = ''
          }
          resolve(value.status)
        })
      })
    },
    validateLayanan() {
      this.$refs.formLayanan.validate()
        .then(this.stepLayanan += 1)

      return new Promise(resolve => {
        this.$on('step-validated', value => {
          if (!value.status) {
            this.errorMsg = value.msg
          } else {
            this.data_utama_id = value.msg.data_utama_id
            this.errorMsg = ''
          }
          resolve(value.status)
        })
      })
    },
    validateDataInformasi() {
      this.$refs.formInformasi.validate()
        .then(this.stepInformasi += 1)

      return new Promise(resolve => {
        this.$on('step-validated', value => {
          if (!value.status) {
            this.errorMsg = value.msg
          } else {
            this.data_utama_id = value.msg.data_utama_id
            this.errorMsg = ''
          }
          resolve(value.status)
        })
      })
    },
    validateAplikasi() {
      if (this.jumlahAplikasi !== 0) {
        return new Promise(resolve => {
          this.stepAplikasi += 1
          this.$on('step-validated', value => {
            if (!value.status) {
              this.errorMsg = value.msg
            } else {
              this.data_utama_id = value.msg.data_utama_id
              this.errorMsg = ''
            }
            resolve(value.status)
          })
        })
      }
      return true
    },
    validateInfra() {
      if (this.jumlahInfra !== 0) {
        return new Promise(resolve => {
          this.stepInfra += 1
          this.$on('step-validated', value => {
            if (!value.status) {
              this.errorMsg = value.msg
            } else {
              this.data_utama_id = value.msg.data_utama_id
              this.errorMsg = ''
            }
            resolve(value.status)
          })
        })
      }
      return true
    },
    validateKeamanan() {
      this.$refs.formKeamanan.validate()
        .then(this.stepKeamanan += 1)

      return new Promise(resolve => {
        this.$on('step-validated', value => {
          if (!value.status) {
            this.errorMsg = value.msg
          } else {
            this.data_utama_id = value.msg.data_utama_id
            this.errorMsg = ''
          }
          resolve(value.status)
        })
      })
    },
    submitForm() {
      this.$router.replace({ name: 'kegiatan-detail', params: { id: this.data_utama_id } })
    },
    stepCheck(e) {
      this.stepChange = e
      this.errorMsg = ''
    },
    stepCompleted(data) {
      this.$emit('step-validated', data)
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
